import classNames from "classnames";
import { memo, useState } from "react";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import SliderBlurImage2 from "@app-assets/images/homepage/slider2-blur.png";
import SliderBlurImage1 from "@app-assets/images/homepage/slider-1-blur.png";
import SliderBlurImage3 from "@app-assets/images/homepage/slider-3-blur.png";
import CustomLazyLoadImage from "@app-components/common/CustomLazyLoadImage";

const SliderImage2 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider2.jpg";
const SliderImage1 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider1.jpg";
const SliderImage3 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider3.jpg";

const SliderImage4 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider4.jpg";

const SliderImage5 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider5.jpg";

const SliderImage6 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider6.jpg";

const SliderImage7 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider7.jpg";

const SliderImage9 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider9.jpg";

const SliderImage10 =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/slider10.jpg";
const imgs = [
  { image: SliderImage1, placeholder: SliderBlurImage1 },
  { image: SliderImage2, placeholder: SliderBlurImage2 },
  { image: SliderImage3, placeholder: SliderBlurImage3 },
  { image: SliderImage4, placeholder: SliderBlurImage3 },
  { image: SliderImage5, placeholder: SliderBlurImage3 },
  { image: SliderImage6, placeholder: SliderBlurImage3 },
  { image: SliderImage7, placeholder: SliderBlurImage3 },
  { image: SliderImage9, placeholder: SliderBlurImage3 },
  { image: SliderImage10, placeholder: SliderBlurImage3 },
];

export default function SliderImages() {
  return (
    <div className="slider-images w-full container gap-[24px] hidden sm:flex ">
      <Swiper
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        grabCursor={true}
        navigation
        speed={1000}
        loop
      >
        {Array(3)
          .fill(0)
          .map((itm, index) => (
            <SwiperSlide key={index}>
              {<SlideImage startIndex={index * 3} />}
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
export const SlideImage = memo(({ startIndex }: { startIndex: number }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="flex imgs">
      {Array(3)
        .fill(0)
        .map((itm, index) => (
          <div
            onClick={() => {
              setActiveIndex(index);
            }}
            key={index}
            className={classNames("flex-1 slider", {
              active: activeIndex === index,
              mid:
                (activeIndex < index && index - activeIndex === 1) ||
                (activeIndex > index && activeIndex - index === 1),
            })}
          >
            <div
              onClick={() => setActiveIndex(index)}
              className={classNames("bg")}
            />
            <CustomLazyLoadImage
              image={imgs[startIndex + index].image}
              placeholder={imgs[startIndex + index].placeholder}
            />
          </div>
        ))}
    </div>
  );
});
