import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "swiper/css";
import "swiper/css/effect-coverflow";

import MeetTextImage from "@app-assets/images/about/ meet-text.png";
import LeadersBlurImage from "@app-assets/images/about/heads-blur.png";

const LeadersImage =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/about/heads.png";

export default function MidBanner() {
  const { t } = useTranslation();
  return (
    <div className="container mid-banner">
      <div className="flex justify-center">
        <img className="img-text" src={MeetTextImage} alt="Meet our team" />
      </div>
      <div className="img-container">
        <LazyLoadImage
          wrapperClassName="cover-center"
          className="img"
          width={"100%"}
          height={"100%"}
          placeholderSrc={LeadersBlurImage}
          src={LeadersImage}
          alt="Head of Many Touches"
        />
        <div className="content-banner">
          <div className="text hidden md:block">
            <div className="mt-text-body-xxl text-white text-center m-auto max-w-[80vw]">
              {t("page.newAbout.bannerContent")}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-text-body-xl mt-8  md:hidden text-center m-auto max-w-[80vw]">
        {t("page.newAbout.bannerContent")}
      </div>
    </div>
  );
}
