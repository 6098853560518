import { useTranslation } from "react-i18next";

import AccessFunctionBlurImage from "@app-assets/images/homepage/access-key-blur.png";
import AllFuntionBlurImage from "@app-assets/images/homepage/all-feature-blur.png";
import PaymentFunctionBlurImage from "@app-assets/images/homepage/payment-blur.png";

import FunctionDetail from "./FunctionDetail";

const AccessFunctionImage =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/access-key.png";
const AllFuntionImage =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/all-feature.png";
const PaymentFunctionImage =
  "https://mt-web-media.s3.ap-southeast-1.amazonaws.com/home/payment.png";

export default function IntroFunctions() {
  const { t } = useTranslation();

  return (
    <div className="intro-functions container flex flex-col gap-[24px]">
      <div className="flex-1">
        <FunctionDetail
          showTitle={false}
          title={t("page.newHome.function.allInOne.title")}
          image={AllFuntionImage}
          content={t("page.landing.functionIntro.func1.desc")}
          imagePlaceholder={AllFuntionBlurImage}
          className="main"
        />
      </div>
      <div className="flex-1 flex flex-row flex-wrap md:flex-nowrap gap-[24px]">
        <FunctionDetail
          image={PaymentFunctionImage}
          title={t("page.newHome.function.payment.title")}
          content={t("page.landing.functionIntro.func2.desc")}
          imagePlaceholder={PaymentFunctionBlurImage}
          className="secondary"
        />
        <FunctionDetail
          image={AccessFunctionImage}
          title={t("page.newHome.function.unlock.title")}
          content={t("page.landing.unlockLimitless.desc")}
          imagePlaceholder={AccessFunctionBlurImage}
          className="secondary"
        />
      </div>
    </div>
  );
}
